import React from 'react';

function Register() {
  return (
    <div className="register">
      <h1>Registrera dig</h1>
      <form>
        <input type="text" placeholder="Namn" required />
        <input type="email" placeholder="E-postadress" required />
        <input type="password" placeholder="Lösenord" required />
        <input type="password" placeholder="Bekräfta lösenord" required />
        <button type="submit">Registrera</button>
      </form>
      <p>Har du redan ett konto? <a href="/login">Logga in här</a></p>
    </div>
  );
}

export default Register;
