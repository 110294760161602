import React from 'react';

function PropertyList({ properties }) {
  return (
    <div className="property-list">
      {/* Add property list content here */}
    </div>
  );
}

export default PropertyList;
