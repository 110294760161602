import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchForm() {
  const [area, setArea] = useState('');
  const [radius, setRadius] = useState('0');
  const [propertyTypes, setPropertyTypes] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?area=${area}&radius=${radius}&types=${propertyTypes.join(',')}`);
  };

  const togglePropertyType = (type) => {
    setPropertyTypes(prev => 
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  };

  return (
    <form onSubmit={handleSubmit} className="search-form">
      <div className="search-tabs">
        <button type="button" className="active">Hyresbostäder</button>
      </div>
      <div className="search-content">
        <h3>Område</h3>
        <input
          type="text"
          value={area}
          onChange={(e) => setArea(e.target.value)}
          placeholder="Skriv område eller adress"
        />
        <h4>Utöka område med</h4>
        <select value={radius} onChange={(e) => setRadius(e.target.value)}>
          <option value="0">+ 0 km</option>
          <option value="1">+ 1 km</option>
          <option value="2">+ 2 km</option>
          <option value="5">+ 5 km</option>
        </select>
        <div className="property-types">
          <button type="button" onClick={() => togglePropertyType('all')} className={propertyTypes.includes('all') ? 'active' : ''}>Alla typer</button>
          <button type="button" onClick={() => togglePropertyType('villa')} className={propertyTypes.includes('villa') ? 'active' : ''}>Villor</button>
          <button type="button" onClick={() => togglePropertyType('apartment')} className={propertyTypes.includes('apartment') ? 'active' : ''}>Lägenheter</button>
          <button type="button" onClick={() => togglePropertyType('farm')} className={propertyTypes.includes('farm') ? 'active' : ''}>Fritidsboenden</button>
          <button type="button" onClick={() => togglePropertyType('other')} className={propertyTypes.includes('other') ? 'active' : ''}>Övriga</button>
        </div>
        <button type="button" className="show-more-filters">Visa sökfilter</button>
        <button type="submit" className="search-button">Hitta hyresbostäder</button>
      </div>
    </form>
  );
}

export default SearchForm;
