import React, { useState, useEffect } from 'react';
import SearchFilters from '../components/SearchFilters';
import PropertyList from '../components/PropertyList';
import PropertyMap from '../components/PropertyMap';
import Pagination from '../components/Pagination';

function SearchResultsPage() {
  const [properties, setProperties] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Fetch properties based on filters and currentPage
    // Update the properties state
  }, [filters, currentPage]);

  return (
    <main>
      <SearchFilters filters={filters} setFilters={setFilters} />
      <section className="search-results">
        <PropertyList properties={properties} />
        <PropertyMap properties={properties} />
      </section>
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </main>
  );
}

export default SearchResultsPage;
