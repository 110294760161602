import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/hyresnet-logo.png'; // Make sure to add your logo file

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt="Hyresnet Logo" />
        </div>
        <div className="footer-sections">
          <div className="footer-section">
            <h3>Kontakt</h3>
            <ul>
              <li><Link to="/kundservice">Kundservice & kontakt</Link></li>
              <li><Link to="/om-hyresnet">Om Hyresnet</Link></li>
              <li><Link to="/press">Press</Link></li>
              <li><Link to="/for-investerare">För investerare</Link></li>
              <li><Link to="/annonsering">Bannerannonsering och reklam</Link></li>
              <li><Link to="/jobb">Jobba hos oss</Link></li>
              <li><Link to="/statistik">Bostadsstatistik</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Appar</h3>
            <ul>
              <li><a href="#" className="app-link"><i className="fab fa-apple"></i> iPhone och iPad</a></li>
              <li><a href="#" className="app-link"><i className="fab fa-android"></i> Android</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Följ Hyresnet</h3>
            <ul>
              <li><a href="#" className="social-link"><i className="far fa-envelope"></i> Hyresnets nyhetsbrev</a></li>
              <li><a href="#" className="social-link"><i className="fab fa-facebook-f"></i> Facebook</a></li>
              <li><a href="#" className="social-link"><i className="fab fa-instagram"></i> Instagram</a></li>
              <li><a href="#" className="social-link"><i className="fab fa-twitter"></i> X</a></li>
              <li><a href="#" className="social-link"><i className="fab fa-linkedin-in"></i> LinkedIn</a></li>
              <li><a href="#" className="social-link"><i className="fab fa-pinterest-p"></i> Pinterest</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
