import React from 'react';
import { useParams } from 'react-router-dom';

function PropertyDetailsPage() {
  const { id } = useParams();

  return (
    <div className="property-details">
      <h1>Property Details</h1>
      <p>Property ID: {id}</p>
      {/* Add more details here as you develop the component */}
    </div>
  );
}

export default PropertyDetailsPage;
