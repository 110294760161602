import React from 'react';

function Pagination({ currentPage, setCurrentPage }) {
  return (
    <div className="pagination">
      {/* Add pagination content here */}
    </div>
  );
}

export default Pagination;
