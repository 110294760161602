import React from 'react';

function SearchFilters({ filters, setFilters }) {
  return (
    <div className="search-filters">
      <h2>Filtrera sökresultat</h2>
      {/* Add search filters content here */}
    </div>
  );
}

export default SearchFilters;
