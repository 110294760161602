import React from 'react';

function UserDashboard() {
  return (
    <div className="user-dashboard">
      <h1>User Dashboard</h1>
      <p>Welcome to your dashboard!</p>
      {/* Add more dashboard content here as you develop the component */}
    </div>
  );
}

export default UserDashboard;
