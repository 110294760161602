import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/hyresnet-logo.png'; // Make sure to add your logo file to the assets folder

function Header() {
  return (
    <header>
      <div className="header-content">
        <Link to="/" className="logo">
          <img src={logo} alt="Hyresnet Logo" />
        </Link>
        <nav className="main-nav">
          <ul>
            <li><NavLink to="/" end>Sök bostad</NavLink></li>
            <li><NavLink to="/list-property">Hyr ut bostad</NavLink></li>
            <li><NavLink to="/build-house">Hur fungerar det?</NavLink></li>
            <li><NavLink to="/news">Nyheter</NavLink></li>
          </ul>
        </nav>
        <div className="user-actions">
          <NavLink to="/login" className="login-link">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            <span>Logga in</span>
          </NavLink>
        </div>
      </div>
    </header>
  );
}

export default Header;
