import React from 'react';
import SearchForm from '../components/SearchForm';

function HomePage() {
  return (
    <main>
      <SearchForm />
      {/* Add other sections of your homepage here */}
    </main>
  );
}

export default HomePage;
